import React, {useState, useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import Image from "../components/image"
import {LazyExpand} from "../components/post/lazy"

import "../styles/parts/ranks.sass"
import star from "../icons/star.svg"
import workers from "../data/images/ranks/workers.png"

const Sem = () => {
    const data = useStaticQuery(graphql`
    query {
      refer: allReferJson {
        edges {
          node {
            vpn
            url
            logo{
              childImageSharp{
                fluid(maxHeight: 140) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sem{
                intro
                features
            }
          }
        }
      }
      score: allDataJson{
        edges {
          node {
            foreignRiview {
              vpns {
                slug
                average {
                  score
                }
              }
            }
          }
        }
      }
    }
  `)
//    const source=""
    const [source, setSource] = useState("")
    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const keyword = urlParams.get('source');
        setSource(keyword);
    },[])
    const content = data.refer.edges
    const score = data.score.edges.filter((edge)=> {return edge.node.foreignRiview !== null})[0].node.foreignRiview
    let vpns = []
    score.vpns.forEach((vpn)=>{
        const slug = vpn.slug
        const vpnRefer = content.filter((refer)=> {return refer.node.vpn === slug;})[0].node
        if (vpnRefer.sem !== null && vpn.average.score){
            vpns.push({
                "slug": slug,
                "url": vpnRefer.url,
                "logo": vpnRefer.logo,
                "intro": vpnRefer.sem.intro,
                "features": vpnRefer.sem.features,
                "score": vpn.average.score
            })
        }
    })

    return(
        <Layout title="2021最好用的翻墙VPN与梯子推荐 - 翻墙101">
            <div id="sem">
                <div className="title" >
                    <div className="container" >
                        <div className="row">
                        <div className="col-7">
                            <h1>最好用的翻墙VPN与梯子推荐</h1>
                            <p><span className="check">&#10004; </span>100% 成功翻墙（6大城市阿里云实测）</p>
                            <p><span className="check">&#10004; </span>访问外区游戏，PS5，Switch外网联机</p>
                            <p><span className="check">&#10004; </span>解锁Youtube（油管）视频， Netflix（奈飞）美剧</p>
                            <p><span className="check">&#10004; </span>无障碍浏览Instagram，Facebook，Twitter以及Clubhouse</p>
                        </div>
                        <div className="col-5">
                            <img id="workers" src={workers} />
                        </div>
                        </div>
                    </div>
                </div>
                <div className="content container">
                    <h6>最后更新时间：2021年2月</h6>
                    <div className="rank">
                        <table className="table table-bordered">
                            <thead className="table-dark">
                                <tr>
                                <th scope="col" style={{width: "25%" }}>名次</th>
                                <th scope="col" style={{width: "45%"}}>特色</th>
                                <th scope="col" style={{width: "15%"}}>评分</th>
                                <th scope="col" style={{width: "15%"}}>官网地址</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vpns.map((vpn,idx)=>(
                                    <tr>
                                    <th scope="row">
                                        <p className="rank-num">{idx+1}</p>
                                        <div className="vpn-card-logo">
                                            <Image src={vpn.logo.childImageSharp} alt={vpn.slug+" logo"} className="vpn-card-logo-img"  />
                                            <div className="rating">
                                                <ul className="line" >
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    {vpn.score >= 9 && <li><img src={star} alt="star" /></li>}
                                                </ul>
                                                {vpn.score >= 9 && <p className="line" >五星好评</p>}
                                                {vpn.score < 9 && <p className="line" >四星好评</p>}
                                            </div>
                                        </div>
                                    </th>
                                    <td>
                                        <p className="intro">{vpn.intro}</p>
                                        {vpn.features.map((feature)=><p className="features">
                                            <span className="check">&#10004; </span><span>{feature}</span>
                                        </p>)}
                                    </td>
                                    <td className="score">
                                        <span className="score-p">{vpn.score}</span>
                                    </td>
                                    <td className="link">
                                    <OutboundLink class="btn vpn-link" role="button" target="_blank" rel="noreferrer nofollow"
                                        href={vpn.url.replace("/vpn/","/key/")+"/?keyword="+source}
                                        >前往官网
                                    </OutboundLink>
                                    </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div id="faq">
                        <h2>常见问题</h2>
                        <LazyExpand title="如果我对速度不满意，能否退款？" >
                            <p>各位网友请放心选择，我们所推荐的VPN皆支持无理由不满意退款，如果有任何不满意的地方，联系客服即可。</p>
                        </LazyExpand>
                        <LazyExpand title="你们的测评可靠么？" >
                            <p>我们通过科学的方法进行测评，不同于诸多博主个人的胡扯甚至拿自家的电脑进行测试。</p>
                            <p>我们通过部署在中国主要城市的服务器, 消耗了大量的计算资源，通过在不同环境下真实模拟用户的体验，得出最为客观公正的结果。</p>
                        </LazyExpand>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Sem